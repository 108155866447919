import { useState } from 'react'
import { useRouter } from 'next/router'

import styles from './Layout.module.scss'

import checkBreakpoint from 'lib/checkBreakpoint'

import HeaderComponent from '../headers'
import SideMenuComponent from '../menus/SideMenuComponent'
import FooterComponent from '../footers'
interface Props {
  children: React.ReactNode
}

const Layout: React.FC<Props> = ({ children }: Props): JSX.Element => {
  const { pathname } = useRouter()

  const { isMobile } = checkBreakpoint()

  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false)

  return (
    <>
      <HeaderComponent
        onPressHamburgerMenu={() => setIsOpenMenu(!isOpenMenu)}
        isOpenMenu={isOpenMenu}
      />
      {isOpenMenu && <SideMenuComponent />}
      <main className={`${styles.main} ${isOpenMenu ? styles.openMenu : ''}`}>{children}</main>
      {pathname in
        {
          '/': '/',
          '/space/[...slug]': '/space/[...slug]',
          '/[country]': '/[country]',
          '/[country]/city/[...slug]': '/[country]/city/[...slug]',
          '/users/delete': '/users/delete',
          '/dedicated/expo2025': '/dedicated/expo2025',
        } && <FooterComponent />}
    </>
  )
}

export default Layout
